import React from "react";
import { FooterHandler } from "terra-one";
import rawFooterData from "./json/footer.json";
import rawGlobalFooterData from "./json/global-footer.json";

const corpUrl = process.env.REACT_APP_CORPORATE_BASE_URL;
const currentLocale = process.env.REACT_APP_LOCALE || 'en';

const formatLink = (link, currentLocale = "en", baseUrl = process.env.REACT_APP_BASE_URL) => {
  if (link && link.startsWith("http")) {
    return link;
  } else if (link?.length > 0) {
    return `${baseUrl}/${currentLocale}/${link}`;
  } else {
    return '';
  }
}

const Footer = () => {
  const sectorFooterLinks = rawFooterData?.data?.footer?.sectorFooterMenusCollection?.items?.map(d => {
    return {
      title: d?.title,
      menuLinks: d?.menuLinksCollection?.items?.map(d => (
          {
            text: d?.text,
            url: formatLink(d?.url?.url, currentLocale),
            Link: null,
            external: false
          }
        )
      )
    }
  });

  const calloutLinks = rawFooterData?.data?.footer?.sectorCalloutLinksCollection?.items?.map(d => {
    return {
      url: formatLink(d?.link?.url?.url, currentLocale),
      title: d.title,
      subTitle: d.subTitle,
      external: false
    }
  });

  const socialLinks = rawFooterData?.data?.footer?.socialLinksCollection?.items?.map(d => {
    return {
      icon: {
        altText: d?.siteName,
        url: d?.icon?.url
      },
      link: {
        url: d?.url
      }
    };
  });

  const logo = {
    url: rawGlobalFooterData?.data?.globalFooter?.mainLogo?.brandfolderAsset
      ? rawGlobalFooterData?.data?.globalFooter?.mainLogo?.brandfolderAsset[0]?.url
      : rawGlobalFooterData?.data?.globalFooter?.mainLogo?.image?.url
      ? rawGlobalFooterData?.data?.globalFooter?.mainLogo?.image?.url
      : null,
    alt: rawGlobalFooterData?.data?.globalFooter?.mainLogo?.altText,
  };

  const legalStuff = rawGlobalFooterData?.data?.globalFooter?.legalStuffCollection?.items?.map(d => {
      const iconImage = d?.iconImage ?
        {
          altText: d?.iconImage?.altText,
          image: {
            file: {
              url: d?.iconImage?.image?.url
            },
            height: d?.iconImage?.image?.height,
            width: d?.iconImage?.image?.width
          }
        } 
        :
        null
      return {
        text: d?.text,
        url: formatLink(d?.url?.url, undefined, corpUrl),
        Link: null,
        external: false,
        iconImage: iconImage,        
      }
    }
  );

  const data = {
    sectorFooterLinks,
    calloutLinks,
    socialLinksTitle: rawFooterData?.data?.footer?.socialLinksTitle,
    socialLinks,
    logo,
    globalFooterText: rawGlobalFooterData?.data?.globalFooter?.text,
    legalStuff,
    globaFooterEnabled: true,
  };
  return (
    <FooterHandler data={ data } />
  );
};

export default Footer;
